import React, { useState, useEffect, useCallback } from 'react';
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useToast,
  VStack,
  HStack,
  useBreakpointValue,
  Box,
  Text,
  SimpleGrid,
  Image,
  Spinner,
  Center,
} from '@chakra-ui/react';
import axios from 'axios';
import { debounce } from 'lodash';

// Similar Items List Component
const SimilarItemsList = ({ items, onItemClick }) => {
  const imageSize = useBreakpointValue({ base: "40px", md: "50px" });
  
  if (!items || items.length === 0) return null;

  return (
    <Box 
      w="100%" 
      bg="gray.800" 
      borderRadius="md" 
      p={2}
      mt={2}
      maxH="200px"
      overflowY="auto"
      borderWidth="1px"
      borderColor="gray.600"
    >
      <Text fontSize="sm" color="gray.400" mb={2} px={2}>
        Similar items found:
      </Text>
      <VStack spacing={2} align="stretch">
        {items.map((item) => (
          <Box
            key={item._id}
            p={2}
            borderRadius="md"
            cursor="pointer"
            _hover={{ bg: 'gray.700' }}
            onClick={() => onItemClick(item)}
          >
            <HStack spacing={3}>
              <Image
                src={item.imageURL || '/images/nia.png'}
                alt={item.itemName}
                boxSize={imageSize}
                objectFit="cover"
                borderRadius="md"
                fallback={
                  <Box
                    boxSize={imageSize}
                    bg="gray.700"
                    borderRadius="md"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Text fontSize="xs" color="gray.500" textAlign="center">
                      No Image
                    </Text>
                  </Box>
                }
              />
              <VStack align="start" spacing={0} flex={1}>
                <Text color="white" fontSize="sm" fontWeight="medium">
                  {item.itemName}
                </Text>
                <HStack spacing={2}>
                  <Text 
                    fontSize="xs" 
                    px={2} 
                    py={0.5} 
                    borderRadius="full"
                    bg={item.store.color}
                    color="white"
                  >
                    {item.store.shortName}
                  </Text>
                  {item.section && (
                    <Text fontSize="xs" color="gray.400">
                      {item.section.sectionName}
                    </Text>
                  )}
                </HStack>
              </VStack>
            </HStack>
          </Box>
        ))}
      </VStack>
    </Box>
  );
};

// Image Search Modal Component
const ImageSearchModal = ({ isOpen, onClose, itemName, onImageSelect }) => {
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const searchImages = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.get(
        'https://customsearch.googleapis.com/customsearch/v1', {
          params: {
            key: process.env.REACT_APP_GOOGLE_API_KEY,
            cx: process.env.REACT_APP_GOOGLE_SEARCH_ENGINE_ID,
            q: `${itemName} product`,
            searchType: 'image',
            num: 10
          }
        }
      );

      if (response.data.items && response.data.items.length > 0) {
        setImages(response.data.items);
      } else {
        setError('No images found');
      }
    } catch (err) {
      console.error('Image search error:', err);
      setError('Failed to fetch images');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen && itemName) {
      searchImages();
    }
  }, [isOpen, itemName]);

  const handleImageSelect = (imageUrl) => {
    onImageSelect(imageUrl);
    onClose();
  };
return (
    <Modal 
      isOpen={isOpen} 
      onClose={onClose}
      size="xl"
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent bg="gray.850">
        <ModalHeader color="white">Select Product Image</ModalHeader>
        <ModalCloseButton color="white" />
        <ModalBody>
          {isLoading ? (
            <Center py={10}>
              <VStack spacing={4}>
                <Spinner size="xl" color="blue.500" />
                <Text color="white">Searching for images...</Text>
              </VStack>
            </Center>
          ) : error ? (
            <Center py={10}>
              <Text color="red.300">{error}</Text>
            </Center>
          ) : (
            <SimpleGrid columns={2} spacing={4}>
              {images.map((image, index) => (
                <Box
                  key={index}
                  cursor="pointer"
                  borderRadius="md"
                  overflow="hidden"
                  onClick={() => handleImageSelect(image.link)}
                  transition="transform 0.2s"
                  _hover={{ transform: 'scale(1.05)' }}
                >
                  <Image
                    src={image.link}
                    alt={image.title}
                    width="100%"
                    height="150px"
                    objectFit="cover"
                    fallback={
                      <Center h="150px" bg="gray.700">
                        <Text color="gray.400">Failed to load</Text>
                      </Center>
                    }
                  />
                </Box>
              ))}
            </SimpleGrid>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

// Main AddItem Component
const AddItem = ({ onItemAdded }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { 
    isOpen: isSectionModalOpen, 
    onOpen: onSectionModalOpen, 
    onClose: onSectionModalClose 
  } = useDisclosure();
  const {
    isOpen: isImageSearchOpen,
    onOpen: onImageSearchOpen,
    onClose: onImageSearchClose
  } = useDisclosure();
  
  const [stores, setStores] = useState([]);
  const [sections, setSections] = useState([]);
  const [uncategorizedId, setUncategorizedId] = useState(null);
  const [newItem, setNewItem] = useState({
    store: '',
    itemName: '',
    imageURL: '',
    section: '',
  });
  const [newSection, setNewSection] = useState({
    sectionName: ''
  });
  const [similarItems, setSimilarItems] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [isAddingSectionLoading, setIsAddingSectionLoading] = useState(false);
  const toast = useToast();

  const apiBaseUrl = process.env.NODE_ENV === 'production'
    ? 'https://list.gqfam.com/api'
    : 'http://localhost:7000/api';

  useEffect(() => {
    fetchStores();
    fetchSections();
  }, []);

  const searchSimilarItems = useCallback(
    debounce(async (query) => {
      if (!query || query.length < 2) {
        setSimilarItems([]);
        return;
      }

      try {
        setIsSearching(true);
        const response = await axios.get(`${apiBaseUrl}/items/search`, {
          headers: { 'x-auth-token': localStorage.getItem('token') },
          params: { query }
        });
        setSimilarItems(response.data);
      } catch (error) {
        console.error('Error searching items:', error);
        setSimilarItems([]);
      } finally {
        setIsSearching(false);
      }
    }, 300),
    []
  );
const fetchStores = async () => {
    try {
      const res = await axios.get(`${apiBaseUrl}/stores`, {
        headers: { 'x-auth-token': localStorage.getItem('token') }
      });
      setStores(res.data);
    } catch (error) {
      console.error('Error fetching stores:', error);
      toast({
        title: "Error fetching stores",
        description: "Unable to load stores. Please try again later.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const fetchSections = async () => {
    try {
      const res = await axios.get(`${apiBaseUrl}/sections`, {
        headers: { 'x-auth-token': localStorage.getItem('token') }
      });
      setSections(res.data);
      
      const uncategorized = res.data.find(section => section.sectionName === 'Uncategorized');
      if (uncategorized) {
        setUncategorizedId(uncategorized._id);
        setNewItem(prev => ({
          ...prev,
          section: uncategorized._id
        }));
      }
    } catch (error) {
      console.error('Error fetching sections:', error);
      toast({
        title: "Error fetching sections",
        description: "Unable to load sections. Please try again later.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'section' && value === 'new') {
      onSectionModalOpen();
      return;
    }
    if (name === 'itemName') {
      searchSimilarItems(value);
    }
    setNewItem({ ...newItem, [name]: value });
  };

  const handleImageSelect = (imageUrl) => {
    setNewItem(prev => ({ ...prev, imageURL: imageUrl }));
    onImageSearchClose();
  };

  const handleSimilarItemClick = (item) => {
    setNewItem({
      ...newItem,
      itemName: item.itemName,
      store: item.store._id,
      section: item.section?._id || uncategorizedId,
      imageURL: item.imageURL || ''
    });
    setSimilarItems([]);
    toast({
      title: "Similar item selected",
      description: "Fields have been pre-filled. You can modify them if needed.",
      status: "info",
      duration: 3000,
      isClosable: true
    });
  };

  const handleNewSectionInputChange = (e) => {
    setNewSection({ sectionName: e.target.value });
  };

  const handleAddSection = async () => {
    try {
      setIsAddingSectionLoading(true);
      
      const response = await axios.post(
        `${apiBaseUrl}/sections`,
        { sectionName: newSection.sectionName },
        { headers: { 'x-auth-token': localStorage.getItem('token') }}
      );

      await fetchSections();
      setNewItem({ ...newItem, section: response.data._id });

      toast({
        title: "Section added successfully",
        status: "success",
        duration: 2000,
        isClosable: true,
      });

      onSectionModalClose();
      setNewSection({ sectionName: '' });
    } catch (error) {
      toast({
        title: "Error adding section",
        description: error.response?.data?.msg || "An error occurred",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    } finally {
      setIsAddingSectionLoading(false);
    }
  };

  const handleSubmit = async () => {
    try {
      const res = await axios.post(`${apiBaseUrl}/items`, newItem, {
        headers: { 'x-auth-token': localStorage.getItem('token') }
      });
      onItemAdded(res.data);
      onClose();
      setNewItem({ store: '', itemName: '', imageURL: '', section: uncategorizedId });
      setSimilarItems([]);
      toast({
        title: "Item added successfully",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error adding item",
        description: error.response?.data?.msg || "An error occurred",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const handleModalClose = () => {
    setNewItem({ store: '', itemName: '', imageURL: '', section: uncategorizedId });
    setSimilarItems([]);
    onClose();
  };

  const buttonSize = useBreakpointValue({ base: 'md', md: 'lg' });
  const modalSize = useBreakpointValue({ base: "full", md: "md" });

  return (
    <>
      <Box display="flex" alignItems="flex-end" width="100%">
        <Button 
          onClick={onOpen} 
          colorScheme="blue" 
          size={buttonSize}
          width="100%"
          height="52px"
          mt={1}
          fontSize="lg"
          fontWeight="semibold"
          bg="blue.500"
          _hover={{ bg: 'blue.600' }}
        >
          Add New Item
        </Button>
      </Box>

 <Modal isOpen={isOpen} onClose={onClose} size={modalSize}>
        <ModalOverlay />
        <ModalContent bg="gray.850">
          <ModalHeader color="white">Add New Item</ModalHeader>
          <ModalCloseButton color="white" />
          <ModalBody>
            <VStack spacing={4}>
              <FormControl isRequired>
                <FormLabel color="white">Item Name</FormLabel>
                <Input 
                  name="itemName" 
                  value={newItem.itemName} 
                  onChange={handleInputChange} 
                  placeholder="Enter item name"
                  bg="gray.700"
                  color="white"
                  borderColor="gray.600"
                  _hover={{ borderColor: 'purple.400' }}
                  _focus={{ 
                    borderColor: 'purple.500',
                    boxShadow: '0 0 0 1px var(--chakra-colors-purple-500)'
                  }}
                />
              </FormControl>

              <FormControl>
                <FormLabel color="white">Section</FormLabel>
                <Select
                  name="section"
                  value={newItem.section}
                  onChange={handleInputChange}
                  bg="gray.700"
                  color="white"
                  _hover={{ borderColor: 'purple.400' }}
                  _focus={{ 
                    borderColor: 'purple.500',
                    boxShadow: '0 0 0 1px var(--chakra-colors-purple-500)'
                  }}
                >
                  {sections
                    .filter(section => section.sectionName === 'Uncategorized')
                    .map(section => (
                      <option key={section._id} value={section._id}
                        style={{ background: '#1a1a1a', color: 'gray' }}>
                        {section.sectionName}
                      </option>
                    ))}
                    
                  <option value="new" 
                    style={{ background: '#1a1a1a', color: 'lightgreen', fontWeight: 'bold' }}>
                    + Add New Section
                  </option>

                  <option value="" disabled 
                    style={{ background: '#1a1a1a', borderTop: '1px solid gray',
                      borderBottom: '1px solid gray', color: 'gray' }}>
                    -------------
                  </option>

                  {sections
                    .filter(section => section.sectionName !== 'Uncategorized')
                    .sort((a, b) => a.sectionName.localeCompare(b.sectionName))
                    .map(section => (
                      <option key={section._id} value={section._id}
                        style={{ background: '#1a1a1a', color: 'white' }}>
                        {section.sectionName}
                      </option>
                    ))}
                </Select>
              </FormControl>

              <FormControl isRequired>
                <FormLabel color="white">Store</FormLabel>
                <Select 
                  name="store" 
                  value={newItem.store} 
                  onChange={handleInputChange}
                  placeholder="Select a store"
                  bg="gray.700"
                  color="white"
                  borderColor="gray.600"
                  _hover={{ borderColor: 'purple.400' }}
                  _focus={{ 
                    borderColor: 'purple.500',
                    boxShadow: '0 0 0 1px var(--chakra-colors-purple-500)'
                  }}
                >
                  {stores.map(store => (
                    <option key={store._id} value={store._id}
                      style={{ background: '#1a1a1a', color: 'white' }}>
                      {store.storeName}
                    </option>
                  ))}
                </Select>
              </FormControl>

              <FormControl>
                <FormLabel color="white">Image</FormLabel>
                <HStack spacing={2} align="start">
                  <VStack flex="1" spacing={2} align="stretch">
                    <Input 
                      name="imageURL" 
                      value={newItem.imageURL} 
                      onChange={handleInputChange}
                      placeholder="Image URL (optional)"
                      bg="gray.700"
                      color="white"
                      borderColor="gray.600"
                      _hover={{ borderColor: 'purple.400' }}
                      _focus={{ 
                        borderColor: 'purple.500',
                        boxShadow: '0 0 0 1px var(--chakra-colors-purple-500)'
                      }}
                    />
                    {newItem.imageURL && (
                      <Image 
                        src={newItem.imageURL}
                        alt="Selected product"
                        maxH="100px"
                        objectFit="contain"
                        borderRadius="md"
                        fallback={
                          <Center h="100px" bg="gray.700" borderRadius="md">
                            <Text color="gray.400">Invalid image URL</Text>
                          </Center>
                        }
                      />
                    )}
                  </VStack>
                  <Button
                    colorScheme="blue"
                    onClick={onImageSearchOpen}
                    isDisabled={!newItem.itemName}
                    title={!newItem.itemName ? "Please enter an item name first"
: "Search for product image"}
                    h="40px"
                    minW="100px"
                  >
                    Search Images
                  </Button>
                </HStack>
              </FormControl>
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button 
              colorScheme="blue" 
              mr={3} 
              onClick={handleSubmit}
              size={buttonSize}
              bg="blue.500"
              _hover={{ bg: 'blue.600' }}
            >
              Add Item
            </Button>
            <Button 
              onClick={onClose}
              size={buttonSize}
              variant="ghost"
              color="white"
              _hover={{ bg: 'whiteAlpha.200' }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={isSectionModalOpen}
        onClose={onSectionModalClose}
        size="md"
      >
        <ModalOverlay />
        <ModalContent bg="gray.850">
          <ModalHeader color="white">Add New Section</ModalHeader>
          <ModalCloseButton color="white" />
          <ModalBody>
            <FormControl isRequired>
              <FormLabel color="white">Section Name</FormLabel>
              <Input
                name="sectionName"
                value={newSection.sectionName}
                onChange={handleNewSectionInputChange}
                placeholder="Enter section name"
                bg="gray.700"
                color="white"
                borderColor="gray.600"
                _hover={{ borderColor: 'purple.400' }}
                _focus={{ 
                  borderColor: 'purple.500',
                  boxShadow: '0 0 0 1px var(--chakra-colors-purple-500)'
                }}
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={handleAddSection}
              isLoading={isAddingSectionLoading}
              bg="blue.500"
              _hover={{ bg: 'blue.600' }}
            >
              Add Section
            </Button>
            <Button
              variant="ghost"
              onClick={onSectionModalClose}
              color="white"
              _hover={{ bg: 'whiteAlpha.200' }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Image Search Modal */}
      <ImageSearchModal
        isOpen={isImageSearchOpen}
        onClose={onImageSearchClose}
        itemName={newItem.itemName}
        onImageSelect={handleImageSelect}
      />
    </>
  );
};

export default AddItem;
