import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  Image,
  VStack,
  HStack,
  Box,
  useToast,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
  Switch,
  Tooltip,
  Text,
} from '@chakra-ui/react';
import axios from 'axios';
import ClearableInput from './ClearableInput';


const DEFAULT_IMAGE = '/images/nia.png';

const ItemModal = ({ isOpen, onClose, item, onUpdate, onDelete, isPantryList = false }) => {
  const [editedItem, setEditedItem] = useState(item);
  const [stores, setStores] = useState([]);
  const [sections, setSections] = useState([]);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [isStoreExclusive, setIsStoreExclusive] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const isAdmin = localStorage.getItem('isAdmin') === 'true';
  const toast = useToast();
  
  const { 
    isOpen: isDeleteAlertOpen, 
    onOpen: onDeleteAlertOpen, 
    onClose: onDeleteAlertClose 
  } = useDisclosure();

  const cancelRef = React.useRef();

  const apiBaseUrl = process.env.NODE_ENV === 'production'
    ? 'https://list.gqfam.com/api'
    : 'http://localhost:7000/api';

  useEffect(() => {
    setEditedItem(item);
    setImageError(false);
    setIsStoreExclusive(item?.isExclusive || false);
    setIsEditMode(false);
    if (isAdmin) {
      fetchStores();
      fetchSections();
    }
  }, [item, isAdmin]);

  const fetchStores = async () => {
    try {
      const res = await axios.get(`${apiBaseUrl}/stores`, {
        headers: { 'x-auth-token': localStorage.getItem('token') }
      });
      setStores(res.data);
    } catch (error) {
      console.error('Error fetching stores:', error);
    }
  };

  const fetchSections = async () => {
    try {
      const res = await axios.get(`${apiBaseUrl}/sections`, {
        headers: { 'x-auth-token': localStorage.getItem('token') }
      });
      setSections(res.data);
    } catch (error) {
      console.error('Error fetching sections:', error);
    }
  };

  const handleImageError = () => {
    setImageError(true);
  };

  const imageUrl = (!editedItem?.imageURL || imageError) ? DEFAULT_IMAGE : editedItem.imageURL;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'imageURL') {
      setImageError(false);
    }
    if (name === 'store' && isStoreExclusive) {
      return;
    }
    setEditedItem(prev => ({
      ...prev,
      [name]: value === '' ? null : value
    }));
  };

  const handleStoreExclusiveToggle = (e) => {
    setIsStoreExclusive(e.target.checked);
    setEditedItem((prev) => ({
      ...prev,
      isExclusive: e.target.checked,
    }));
  };

  const handleDelete = async () => {
    try {
      setIsDeleting(true);
      await onDelete(item._id);
      onDeleteAlertClose();
      onClose();
    } catch (error) {
      console.error('Error deleting item:', error);
      toast({
        title: "Error deleting item",
        description: error.response?.data?.msg || "An error occurred",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    } finally {
      setIsDeleting(false);
    }
  };

  const handleSave = async () => {
    try {
      setIsUpdating(true);
      
      if (!editedItem?.itemName?.trim()) {
        toast({
          title: "Item name is required",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
        return;
      }

      if (!editedItem?.store) {
        toast({
          title: "Store selection is required",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
        return;
      }

      await onUpdate(editedItem);
      
      toast({
        title: "Item updated successfully",
        status: "success",
        duration: 2000,
        isClosable: true,
      });

      setIsEditMode(false);
      onClose();
    } catch (error) {
      toast({
        title: "Error updating item",
        description: error.response?.data?.msg || "An error occurred",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    } finally {
      setIsUpdating(false);
    }
  };

  const formatDateTime = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      timeZone: 'America/New_York',
      hour12: true
    }) + ' EST';
  };

  const renderViewContent = () => (
    <VStack spacing={6}>
      <HStack width="100%" spacing={4} align="start">
        <VStack flex="1" spacing={6} align="stretch">
          <Box>
            <Text color="gray.400" fontSize="sm">Item Name</Text>
            <Text color="white" fontSize="xl" fontWeight="medium">
              {editedItem?.itemName}
            </Text>
          </Box>

          <Box>
            <Text color="gray.400" fontSize="sm">Store</Text>
            <Text color="white" fontSize="lg" fontWeight="medium">
              {editedItem?.store?.storeName}
            </Text>
          </Box>

          <Box>
            <Text color="gray.400" fontSize="sm">Section</Text>
            <Text color="white" fontSize="lg" fontWeight="medium">
              {editedItem?.section?.sectionName || 'Uncategorized'}
            </Text>
          </Box>

          {!isPantryList && editedItem?.needRequests?.length > 0 && (
            <Box>
              <Text color="gray.400" fontSize="md" mb={3}>Requested By</Text>
              <VStack spacing={2} align="stretch">
                {editedItem?.needRequests?.map((request, index) => (
                  <Box 
                    key={index}
                    bg="gray.700"
                    p={4}
                    borderRadius="md"
                    borderLeft="4px solid"
                    borderLeftColor="purple.500"
                  >
                    <Text color="white" fontSize="lg" fontWeight="semibold" mb={1}>
                      {request.username}
                    </Text>
                    <Text color="gray.300" fontSize="md">
                      {formatDateTime(request.dateRequested)}
                    </Text>
                  </Box>
                ))}
              </VStack>
            </Box>
          )}
        </VStack>

        <Box width="120px" height="120px" flexShrink={0}>
          <Image 
            src={imageUrl}
            alt={editedItem?.itemName} 
            objectFit="cover"
            width="100%"
            height="100%"
            borderRadius="md"
            onError={handleImageError}
          />
        </Box>
      </HStack>
    </VStack>
  );

  const renderEditContent = () => (
    <VStack spacing={4}>
      <HStack width="100%" spacing={4} align="start">
        <VStack flex="1" spacing={4} align="stretch">
          <FormControl isRequired>
            <FormLabel color="white">Item Name</FormLabel>
            <ClearableInput
              name="itemName" 
              value={editedItem?.itemName || ''} 
              onChange={handleInputChange} 
              bg="gray.700"
              color="white"
            />
          </FormControl>

          <FormControl>
            <FormLabel color="white">Section</FormLabel>
            <Select
              name="section"
              value={editedItem?.section?._id || editedItem?.section || ''}
              onChange={handleInputChange}
              bg="gray.700"
              color="white"
            >
              {sections.map(section => (
                <option 
                  key={section._id} 
                  value={section._id}
                  style={{ background: '#1a1a1a', color: 'white' }}
                >
                  {section.sectionName}
                </option>
              ))}
            </Select>
          </FormControl>

          <FormControl isRequired>
            <HStack justify="space-between" align="center">
              <FormLabel color="white" mb={0}>Store</FormLabel>
              <Tooltip 
                label={isStoreExclusive ? "Store cannot be changed while locked" : "Lock store selection"}
                placement="top"
              >
                <HStack spacing={2}>
                  <Box color="white" fontSize="sm">Exclusive</Box>
                  <Switch 
                    colorScheme="purple"
                    isChecked={isStoreExclusive}
                    onChange={handleStoreExclusiveToggle}
                  />
                </HStack>
              </Tooltip>
            </HStack>
            <Select
              name="store"
              value={editedItem?.store?._id || editedItem?.store || ''}
              onChange={handleInputChange}
              bg="gray.700"
              color="white"
              isDisabled={isStoreExclusive}
            >
              {stores.map(store => (
                <option 
                  key={store._id} 
                  value={store._id}
                  style={{ background: '#1a1a1a', color: 'white' }}
                >
                  {store.storeName}
                </option>
              ))}
            </Select>
          </FormControl>

          <FormControl>
            <FormLabel color="white">Image URL</FormLabel>
            <ClearableInput
              name="imageURL" 
              value={editedItem?.imageURL || ''} 
              onChange={handleInputChange}
              placeholder="Enter image URL (optional)"
              bg="gray.700"
              color="white"
            />
          </FormControl>
        </VStack>

        <Box width="120px" height="120px" flexShrink={0}>
          <Image 
            src={imageUrl}
            alt={editedItem?.itemName} 
            objectFit="cover"
            width="100%"
            height="100%"
            borderRadius="md"
            onError={handleImageError}
          />
        </Box>
      </HStack>
    </VStack>
  );

  return (
    <>
      <Modal 
        isOpen={isOpen} 
        onClose={() => {
          setIsEditMode(false);
          onClose();
        }}
        closeOnOverlayClick={true}
        isCentered
        size={{ base: "full", md: "2xl" }}
      >
        <ModalOverlay />
        <ModalContent bg="gray.850">
          <ModalHeader color="white">{editedItem?.itemName}</ModalHeader>
          <ModalCloseButton color="white" />
          <ModalBody>
            {isEditMode ? renderEditContent() : renderViewContent()}
          </ModalBody>

          <ModalFooter>
            <HStack spacing={2} width="100%">
              {isEditMode ? (
                <>
                  <Button 
                    colorScheme="green" 
                    onClick={handleSave}
                    flex="1"
                    isLoading={isUpdating}
                  >
                    Save
                  </Button>
                  <Button 
                    colorScheme="red" 
                    onClick={onDeleteAlertOpen}
                    flex="1"
                  >
                    Delete
                  </Button>
                </>
              ) : (
                <>
                  {isAdmin && (
                    <Button 
                      colorScheme="blue" 
                      onClick={() => setIsEditMode(true)}
                      flex="1"
                    >
                      Edit
                    </Button>
                  )}
                  <Button 
                    onClick={onClose}
                    flex="1"
                    variant="ghost"
                    color="white"
                  >
                    Close
                  </Button>
                </>
              )}
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <AlertDialog
        isOpen={isDeleteAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={onDeleteAlertClose}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent bg="gray.850">
            <AlertDialogHeader color="white">Delete Item</AlertDialogHeader>
            <AlertDialogBody color="white">
              Are you sure you want to delete "{editedItem?.itemName}"? This action cannot be undone.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button 
                ref={cancelRef} 
                onClick={onDeleteAlertClose}
                variant="ghost"
                color="white"
              >
                Cancel
              </Button>
              <Button 
                colorScheme="red" 
                onClick={handleDelete} 
                ml={3}
                isLoading={isDeleting}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default ItemModal;