import React, { useState, useEffect } from 'react';
import {
  Container,
  Grid,
  GridItem,
  Box,
  Heading,
  Text,
  FormControl,
  FormLabel,
  Input,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useToast,
  HStack,
  IconButton,
} from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import ClearableInput from './ClearableInput';
import axios from 'axios';

const UserProfile = () => {
  const [userData, setUserData] = useState(null);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [requestedItems, setRequestedItems] = useState({ items: [], totalPages: 0 });
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingItems, setIsLoadingItems] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortField, setSortField] = useState('lastRequested');
  const [sortDirection, setSortDirection] = useState('desc');
  const toast = useToast();

  const apiBaseUrl = process.env.NODE_ENV === 'production'
    ? 'https://list.gqfam.com/api'
    : 'http://localhost:7000/api';

  useEffect(() => {
    fetchUserData();
    fetchRequestedItems();
  }, [currentPage, sortField, sortDirection]);

  const fetchUserData = async () => {
    try {
      const response = await axios.get(`${apiBaseUrl}/users/profile`, {
        headers: { 'x-auth-token': localStorage.getItem('token') }
      });
      setUserData(response.data);
    } catch (error) {
      console.error('Error fetching profile:', error);
    }
  };

  const fetchRequestedItems = async () => {
    setIsLoadingItems(true);
    try {
      console.log('Fetching items with params:', { currentPage, sortField, sortDirection });
      const response = await axios.get(`${apiBaseUrl}/items/user-history`, {
        headers: { 'x-auth-token': localStorage.getItem('token') },
        params: {
          page: currentPage,
          sort: sortField,
          direction: sortDirection
        }
      });
      
      console.log('Received response:', response.data);
      setRequestedItems(response.data);
    } catch (error) {
      console.error('Error fetching items:', error);
      toast({
        title: "Error fetching items",
        description: error.response?.data?.msg || "An error occurred",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoadingItems(false);
    }
  };

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    if (!password || !confirmPassword) {
      toast({
        title: "Please fill in all fields",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    
    if (password !== confirmPassword) {
      toast({
        title: "Passwords don't match",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setIsLoading(true);
    try {
      await axios.put(
        `${apiBaseUrl}/users/password`, 
        { password },
        { headers: { 'x-auth-token': localStorage.getItem('token') }}
      );
      
      toast({
        title: "Password updated successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      
      setPassword('');
      setConfirmPassword('');
    } catch (error) {
      toast({
        title: "Error updating password",
        description: error.response?.data?.msg || "An error occurred",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSort = (field) => {
    if (sortField === field) {
      setSortDirection(sortDirection === 'desc' ? 'asc' : 'desc');
    } else {
      setSortField(field);
      setSortDirection('desc');
    }
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  return (
    <Container maxW="container.xl" py={8}>
      <Grid templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }} gap={6} mb={8}>
        {/* Account Info */}
        <GridItem>
          <Box bg="gray.850" p={6} borderRadius="lg" borderWidth="1px" borderColor="gray.700">
            <Heading size="md" mb={4} color="white">Account Info</Heading>
            <FormControl>
              <FormLabel color="gray.300">Username</FormLabel>
              <ClearableInput
                value={userData?.username || ''}
                isReadOnly
                bg="gray.700"
                color="white"
                opacity={0.8}
              />
            </FormControl>
          </Box>
        </GridItem>

        {/* Password Change */}
        <GridItem>
          <Box bg="gray.850" p={6} borderRadius="lg" borderWidth="1px" borderColor="gray.700">
            <Heading size="md" mb={4} color="white">Change Password</Heading>
            <form onSubmit={handlePasswordChange}>
              <FormControl mb={4}>
                <FormLabel color="gray.300">New Password</FormLabel>
                <ClearableInput
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  bg="gray.700"
                  color="white"
                />
              </FormControl>
              
              <FormControl mb={4}>
                <FormLabel color="gray.300">Confirm Password</FormLabel>
                <ClearableInput
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  bg="gray.700"
                  color="white"
                />
              </FormControl>

              <Button
                type="submit"
                colorScheme="purple"
                isLoading={isLoading}
                width="full"
              >
                Update Password
              </Button>
            </form>
          </Box>
        </GridItem>
      </Grid>

      {/* Request History */}
      <Box>
        <HStack justify="space-between" mb={4}>
          <Heading size="lg" color="white">Request History</Heading>
          <Button
            size="sm"
            colorScheme="purple"
            variant="outline"
            onClick={fetchRequestedItems}
            isLoading={isLoadingItems}
          >
            Refresh
          </Button>
        </HStack>

        <Box bg="gray.850" borderRadius="lg" borderWidth="1px" borderColor="gray.700" overflow="hidden">
          <Table variant="simple">
            <Thead bg="gray.800">
              <Tr>
                <Th 
                  color="gray.300"
                  cursor="pointer"
                  onClick={() => handleSort('itemName')}
                >
                  <HStack>
                    <Text>Item Name</Text>
                    {sortField === 'itemName' && (
                      <IconButton
                        icon={sortDirection === 'desc' ? <ChevronDownIcon /> : <ChevronUpIcon />}
                        variant="ghost"
                        size="xs"
                      />
                    )}
                  </HStack>
                </Th>
                <Th 
                  color="gray.300"
                  cursor="pointer"
                  onClick={() => handleSort('store')}
                >
                  <HStack>
                    <Text>Store</Text>
                    {sortField === 'store' && (
                      <IconButton
                        icon={sortDirection === 'desc' ? <ChevronDownIcon /> : <ChevronUpIcon />}
                        variant="ghost"
                        size="xs"
                      />
                    )}
                  </HStack>
                </Th>
                <Th 
                  color="gray.300"
                  cursor="pointer"
                  onClick={() => handleSort('requestCount')}
                  isNumeric
                >
                  <HStack justify="flex-end">
                    <Text>Times Requested</Text>
                    {sortField === 'requestCount' && (
                      <IconButton
                        icon={sortDirection === 'desc' ? <ChevronDownIcon /> : <ChevronUpIcon />}
                        variant="ghost"
                        size="xs"
                      />
                    )}
                  </HStack>
                </Th>
                <Th 
                  color="gray.300"
                  cursor="pointer"
                  onClick={() => handleSort('lastRequested')}
                >
                  <HStack>
                    <Text>Last Requested</Text>
                    {sortField === 'lastRequested' && (
                      <IconButton
                        icon={sortDirection === 'desc' ? <ChevronDownIcon /> : <ChevronUpIcon />}
                        variant="ghost"
                        size="xs"
                      />
                    )}
                  </HStack>
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {isLoadingItems ? (
                <Tr>
                  <Td colSpan={4} textAlign="center">Loading...</Td>
                </Tr>
              ) : requestedItems.items?.length === 0 ? (
                <Tr>
                  <Td colSpan={4} textAlign="center">
                    <Text color="gray.500">No items requested yet</Text>
                  </Td>
                </Tr>
              ) : (
                requestedItems.items?.map((item, index) => (
                  <Tr key={index} _hover={{ bg: 'gray.700' }}>
                    <Td color="white">{item.itemName}</Td>
                    <Td color="white">{item.store}</Td>
                    <Td color="white" isNumeric>{item.requestCount}</Td>
                    <Td color="white">{formatDate(item.lastRequested)}</Td>
                  </Tr>
                ))
              )}
            </Tbody>
          </Table>
        </Box>
      </Box>
    </Container>
  );
};

export default UserProfile;
