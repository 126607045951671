import React from 'react';
import { 
  Box, 
  Button, 
  Container,
  HStack,
  Image,
  IconButton,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';

const NavButton = ({ to, children, isActive, onClick }) => (
  <Button
    as={RouterLink}
    to={to}
    variant={isActive ? "solid" : "ghost"}
    colorScheme="purple"
    size={{ base: "md", lg: "lg" }}
    px={{ base: 4, lg: 8 }}
    onClick={onClick}
    _hover={{
      bg: isActive ? 'purple.600' : 'whiteAlpha.200',
    }}
  >
    {children}
  </Button>
);

const MobileNavButton = ({ to, children, isActive, onClick }) => (
  <Button
    as={RouterLink}
    to={to}
    variant={isActive ? "solid" : "ghost"}
    colorScheme="purple"
    size="lg"
    width="100%"
    onClick={onClick}
    _hover={{
      bg: isActive ? 'purple.600' : 'whiteAlpha.200',
    }}
  >
    {children}
  </Button>
);

const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isAuthenticated = !!localStorage.getItem('token');
  const isAdmin = localStorage.getItem('isAdmin') === 'true';
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('isAdmin');
    localStorage.removeItem('userId');
    navigate('/login');
  };

  return (
    <Box 
      bg="gray.850"
      borderBottom="1px" 
      borderColor="gray.700"
      position="sticky"
      top={0}
      zIndex={10}
      boxShadow="lg"
    >
      <Container maxW="container.xl">
        <Box h={{ base: 16, md: 20 }} display="flex" alignItems="center" justifyContent="space-between">
          <Box
            as={RouterLink}
            to="/"
            display="flex"
            alignItems="center"
            h={{ base: "40px", md: "50px" }}
            _hover={{ opacity: 0.8 }}
          >
            <Image
              src="/logo.png"
              alt="List Buddy"
              h="100%"
              w="auto"
              objectFit="contain"
            />
          </Box>

          {isAuthenticated && (
            <>
              {/* Desktop Navigation */}
              <HStack spacing={2} display={{ base: "none", md: "flex" }}>
                <NavButton 
                  to="/" 
                  isActive={location.pathname === '/'}
                >
                  Shopping List
                </NavButton>
                <NavButton 
                  to="/pantry" 
                  isActive={location.pathname === '/pantry'}
                >
                  Items
                </NavButton>
                <NavButton 
                  to="/profile" 
                  isActive={location.pathname === '/profile'}
                >
                  Profile
                </NavButton>
                {isAdmin && (
                  <>
                    <NavButton 
                      to="/admin" 
                      isActive={location.pathname === '/admin'}
                    >
                      Admin
                    </NavButton>
                  </>
                )}
                <Button
                  colorScheme="red"
                  variant="outline"
                  size={{ base: "md", lg: "lg" }}
                  onClick={handleLogout}
                  ml={4}
                  _hover={{
                    bg: 'red.900',
                  }}
                >
                  Logout
                </Button>
              </HStack>

              {/* Mobile Hamburger Menu */}
              <IconButton
                display={{ base: "flex", md: "none" }}
                aria-label="Open menu"
                icon={<HamburgerIcon boxSize={8} />}
                onClick={onOpen}
                variant="outline"
                colorScheme="purple"
                size="lg"
                border="2px"
                borderColor="purple.400"
                _hover={{
                  bg: 'whiteAlpha.200',
                  borderColor: 'purple.300'
                }}
                p={2}
              />

              {/* Mobile Drawer */}
              <Drawer
                isOpen={isOpen}
                placement="right"
                onClose={onClose}
                size="xs"
              >
                <DrawerOverlay />
                <DrawerContent bg="gray.850">
                  <DrawerCloseButton color="white" size="lg" />
                  <DrawerHeader borderBottomWidth="1px" color="white">
                    Menu
                  </DrawerHeader>
                  <DrawerBody>
                    <VStack spacing={4} mt={4}>
                      <MobileNavButton 
                        to="/" 
                        isActive={location.pathname === '/'}
                        onClick={onClose}
                      >
                        Shopping List
                      </MobileNavButton>
                      <MobileNavButton 
                        to="/pantry" 
                        isActive={location.pathname === '/pantry'}
                        onClick={onClose}
                      >
                        Items
                      </MobileNavButton>
                      <MobileNavButton 
                        to="/profile" 
                        isActive={location.pathname === '/profile'}
                        onClick={onClose}
                      >
                        Profile
                      </MobileNavButton>
                      {isAdmin && (
                        <>
                          <MobileNavButton 
                            to="/admin" 
                            isActive={location.pathname === '/admin'}
                            onClick={onClose}
                          >
                            Admin
                          </MobileNavButton>
                        </>
                      )}
                      <Button
                        colorScheme="red"
                        variant="outline"
                        size="lg"
                        width="100%"
                        onClick={() => {
                          handleLogout();
                          onClose();
                        }}
                        _hover={{
                          bg: 'red.900',
                        }}
                      >
                        Logout
                      </Button>
                    </VStack>
                  </DrawerBody>
                </DrawerContent>
              </Drawer>
            </>
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default Navbar;