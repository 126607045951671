import React from 'react';
import {
  Input,
  InputGroup,
  InputRightElement,
  InputLeftElement,
  IconButton,
  useBreakpointValue,
} from '@chakra-ui/react';
import { CloseIcon, SearchIcon } from '@chakra-ui/icons';

const ClearableInput = ({ value, onChange, onClear, isSearch, ...props }) => {
  // Adjust icon sizes based on screen size
  const iconSize = useBreakpointValue({ base: "12px", md: "8px" });
  const buttonSize = useBreakpointValue({ base: "sm", md: "xs" });
  const leftIconSize = useBreakpointValue({ base: "20px", md: "16px" });

  const handleClear = (e) => {
    e.stopPropagation();
    
    if (onClear) {
      onClear();
    } else if (onChange) {
      onChange({ target: { name: props.name, value: '' } });
    }
  };

  return (
    <InputGroup>
      {isSearch && (
        <InputLeftElement 
          pointerEvents="none"
          h="100%"
          pl={{ base: "4", md: "3" }}
        >
          <SearchIcon color="gray.300" boxSize={leftIconSize} />
        </InputLeftElement>
      )}
      <Input
        value={value}
        onChange={onChange}
        pl={isSearch ? { base: "12", md: "10" } : "4"}
        pr={{ base: "12", md: "8" }}
        {...props}
      />
      {value && (
        <InputRightElement h="100%">
          <IconButton
            icon={<CloseIcon boxSize={iconSize} />}
            size={buttonSize}
            variant="ghost"
            color="gray.400"
            onClick={handleClear}
            aria-label="Clear input"
            _hover={{ 
              bg: 'whiteAlpha.200',
              color: 'white'
            }}
            h={{ base: "32px", md: "24px" }}
            w={{ base: "32px", md: "24px" }}
          />
        </InputRightElement>
      )}
    </InputGroup>
  );
};

export default ClearableInput;