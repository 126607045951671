// src/components/Logs.js
import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Input,
  Select,
  HStack,
  Button,
  Text,
  Badge,
  VStack,
  Heading,
  useToast,
  IconButton,
} from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import axios from 'axios';

const Logs = () => {
  const [logs, setLogs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedAction, setSelectedAction] = useState('');
  const [uniqueActions, setUniqueActions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const toast = useToast();

  const apiBaseUrl = process.env.NODE_ENV === 'production'
    ? 'https://list.gqfam.com/api'
    : 'http://localhost:7000/api';

  const fetchLogs = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${apiBaseUrl}/logs`, {
        headers: { 'x-auth-token': localStorage.getItem('token') },
        params: {
          page: currentPage,
          search: searchTerm,
          action: selectedAction
        }
      });

      setLogs(response.data.logs);
      setTotalPages(response.data.totalPages);
      setUniqueActions(response.data.uniqueActions);
    } catch (error) {
      toast({
        title: "Error fetching logs",
        description: error.response?.data?.msg || "An error occurred",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchLogs();
  }, [currentPage, searchTerm, selectedAction]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', {
      dateStyle: 'medium',
      timeStyle: 'medium',
      timeZone: 'America/New_York'
    }).format(date);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const handleActionFilter = (e) => {
    setSelectedAction(e.target.value);
    setCurrentPage(1);
  };

  const getActionColor = (action) => {
    switch (action) {
      case 'Created Item':
        return 'green';
      case 'Deleted Item':
        return 'red';
      case 'Added to Shopping List':
        return 'blue';
      case 'Marked as Have':
        return 'purple';
      default:
        return 'gray';
    }
  };

  return (
    <Container maxW="container.xl" py={8}>
      <VStack spacing={6} align="stretch">
        <Heading 
          size="2xl" 
          color="white"
          textAlign="center"
          fontWeight="bold"
        >
          ACTIVITY LOG
        </Heading>

        <HStack spacing={4}>
          <Input
            placeholder="Search by item name..."
            value={searchTerm}
            onChange={handleSearch}
            bg="gray.700"
            color="white"
          />
          <Select
            value={selectedAction}
            onChange={handleActionFilter}
            bg="gray.700"
            color="white"
            maxW="300px"
          >
            <option value="">All Actions</option>
            {uniqueActions.map(action => (
              <option key={action} value={action}>{action}</option>
            ))}
          </Select>
        </HStack>

        <Box overflowX="auto" borderRadius="lg" borderWidth="1px" borderColor="gray.700">
          <Table variant="simple">
            <Thead bg="gray.800">
              <Tr>
                <Th color="gray.300">Item Name</Th>
                <Th color="gray.300">Action</Th>
                <Th color="gray.300">User</Th>
                <Th color="gray.300">Device</Th>
                <Th color="gray.300">Date & Time (EST)</Th>
              </Tr>
            </Thead>
            <Tbody>
              {isLoading ? (
                <Tr>
                  <Td colSpan={5} textAlign="center">Loading...</Td>
                </Tr>
              ) : logs.length === 0 ? (
                <Tr>
                  <Td colSpan={5} textAlign="center">
                    <Text color="gray.500">No logs found</Text>
                  </Td>
                </Tr>
              ) : (
                logs.map((log) => (
                  <Tr key={log._id} _hover={{ bg: 'gray.700' }}>
                    <Td color="white">{log.itemName}</Td>
                    <Td>
                      <Badge
                        colorScheme={getActionColor(log.action)}
                        px={2}
                        py={1}
                        borderRadius="full"
                      >
                        {log.action}
                      </Badge>
                    </Td>
                    <Td color="white">{log.user.username}</Td>
                    <Td>
                      <Badge
                        colorScheme={log.device === 'Mobile' ? 'purple' : 'blue'}
                        px={2}
                        py={1}
                        borderRadius="full"
                      >
                        {log.device}
                      </Badge>
                    </Td>
                    <Td color="white">{formatDate(log.timestamp)}</Td>
                  </Tr>
                ))
              )}
            </Tbody>
          </Table>
        </Box>

        {/* Pagination */}
        {totalPages > 1 && (
          <HStack justify="center" spacing={4}>
            <IconButton
              icon={<ChevronLeftIcon />}
              onClick={() => setCurrentPage(p => Math.max(1, p - 1))}
              isDisabled={currentPage === 1}
              colorScheme="purple"
              variant="outline"
            />
            <Text color="white">
              Page {currentPage} of {totalPages}
            </Text>
            <IconButton
              icon={<ChevronRightIcon />}
              onClick={() => setCurrentPage(p => Math.min(totalPages, p + 1))}
              isDisabled={currentPage === totalPages}
              colorScheme="purple"
              variant="outline"
            />
          </HStack>
        )}
      </VStack>
    </Container>
  );
};

export default Logs;