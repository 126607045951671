// src/contexts/LayoutContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';

const LayoutContext = createContext();

export function LayoutProvider({ children }) {
  const [isDesktopMode, setIsDesktopMode] = useState(
    localStorage.getItem('viewMode') === 'desktop'
  );

  const toggleViewMode = () => {
    const newMode = !isDesktopMode;
    setIsDesktopMode(newMode);
    localStorage.setItem('viewMode', newMode ? 'desktop' : 'mobile');
  };

  return (
    <LayoutContext.Provider value={{ isDesktopMode, toggleViewMode }}>
      {children}
    </LayoutContext.Provider>
  );
}

export function useLayout() {
  const context = useContext(LayoutContext);
  if (context === undefined) {
    throw new Error('useLayout must be used within a LayoutProvider');
  }
  return context;
}