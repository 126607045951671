import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Image, 
  Text, 
  HStack, 
  VStack, 
  Icon, 
  Button,
  Badge,
} from '@chakra-ui/react';
import { StarIcon } from '@chakra-ui/icons';
import axios from 'axios';

const PlaceholderImage = () => (
  <Box
    bg="gray.700"
    w="100%"
    h="100%"
    display="flex"
    alignItems="center"
    justifyContent="center"
    flexDirection="column"
  >
    <Text
      color="gray.500"
      fontSize="sm"
      fontWeight="medium"
      textAlign="center"
    >
      NO IMAGE
      <br />
      AVAILABLE
    </Text>
  </Box>
);

const ItemCard = ({ item, onClick, onStatusChange, onWantItem, onFavoriteChange, isPantryList = false }) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const [isWanting, setIsWanting] = useState(false);
  const [isUpdatingFavorite, setIsUpdatingFavorite] = useState(false);
  const isAdmin = localStorage.getItem('isAdmin') === 'true';
  const currentUserId = localStorage.getItem('userId');

  // Convert IDs to strings for proper comparison
  const normalizedNeedRequests = (item.needRequests || []).map(id => id?.toString());
  const normalizedFavorites = (item.favorites || []).map(id => id?.toString());
  
  // Calculate favorite status
  const [isFavorite, setIsFavorite] = useState(normalizedFavorites.includes(currentUserId));

  // Calculate need status and counts
  const userNeedsItem = item.needRequests.some(request => request.user === currentUserId);
  const otherUsersNeedCount = item.status === 'Need' ? 
    normalizedNeedRequests.filter(id => id !== currentUserId).length : 0;

  useEffect(() => {
    setIsFavorite(normalizedFavorites.includes(currentUserId));
  }, [item.favorites, currentUserId, normalizedFavorites]);

  const handleCardClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowConfirm(false);
    if (onClick) {
      onClick();
    }
  };

  const handleFavoriteClick = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (isUpdatingFavorite) return;

    setIsUpdatingFavorite(true);
    try {
      const response = await axios.post(
        `${process.env.NODE_ENV === 'production' ? 'https://list.gqfam.com/api' : 'http://localhost:7000/api'}/items/${item._id}/favorite`,
        {},
        { headers: { 'x-auth-token': localStorage.getItem('token') } }
      );
      setIsFavorite(response.data.isFavorite);
      if (onFavoriteChange) {
        onFavoriteChange(response.data);
      }
    } catch (error) {
      console.error('Error updating favorite status:', error);
    } finally {
      setIsUpdatingFavorite(false);
    }
  };

  const handleStatusClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    
    if (isPantryList) {
      if (userNeedsItem) {
        // Remove user from needRequests
        onStatusChange(item._id, 'Have', { removeFromNeedRequests: currentUserId });
      } else {
        // Add user to needRequests
        onStatusChange(item._id, 'Need', { addToNeedRequests: currentUserId });
      }
      return;
    }

    // Shopping List buttons (admin only)
    if (isAdmin) {
      if (showConfirm) {
        onStatusChange(item._id, 'Have');
        setShowConfirm(false);
      } else {
        setShowConfirm(true);
      }
    }
  };

  const renderActionButton = () => {
    if (isPantryList) {
      if (userNeedsItem) {
        return (
          <Button
            height="32px"
            width="130px"
            onClick={handleStatusClick}
            colorScheme="orange"
            variant="solid"
            fontSize="sm"
            fontWeight="bold"
            boxShadow="md"
            _hover={{
              transform: 'translateY(-1px)',
              boxShadow: 'lg',
            }}
            _active={{
              transform: 'translateY(1px)',
              boxShadow: 'sm',
            }}
          >
            Don't Need
          </Button>
        );
      } else {
        return (
          <Button
            height="32px"
            width="130px"
            onClick={handleStatusClick}
            colorScheme="blue"
            variant="solid"
            fontSize="sm"
            fontWeight="bold"
            boxShadow="md"
            _hover={{
              transform: 'translateY(-1px)',
              boxShadow: 'lg',
            }}
            _active={{
              transform: 'translateY(1px)',
              boxShadow: 'sm',
            }}
          >
            Need Item
          </Button>
        );
      }
    }

    // Shopping List buttons (admin only)
    if (!isAdmin) return null;
    
    if (showConfirm) {
      return (
        <Button
          height="32px"
          width="130px"
          onClick={handleStatusClick}
          colorScheme="yellow"
          variant="solid"
          fontSize="sm"
          fontWeight="bold"
          boxShadow="md"
          _hover={{
            transform: 'translateY(-1px)',
            boxShadow: 'lg',
          }}
          _active={{
            transform: 'translateY(1px)',
            boxShadow: 'sm',
          }}
        >
          Confirm Have?
        </Button>
      );
    } else {
      return (
        <Button
          height="32px"
          width="130px"
          onClick={handleStatusClick}
          colorScheme="red"
          variant="solid"
          fontSize="sm"
          fontWeight="bold"
          boxShadow="md"
          _hover={{
            transform: 'translateY(-1px)',
            boxShadow: 'lg',
          }}
          _active={{
            transform: 'translateY(1px)',
            boxShadow: 'sm',
          }}
        >
          Have Item
        </Button>
      );
    }
  };

  return (
    <Box
      w="100%"
      borderRadius="lg"
      overflow="hidden"
      bg="gray.850"
      borderWidth="1px"
      borderColor="gray.700"
      cursor="pointer"
      position="relative"
      transition="all 0.2s ease"
      onClick={handleCardClick}
      _hover={{
        transform: 'translateY(-2px)',
        boxShadow: 'dark-lg',
        borderColor: 'gray.600',
      }}
    >
      <Box
        w="100%"
        h="4px"
        bg={item.store.color}
      />

      <HStack spacing={0} align="stretch" h="140px">
        <Box
          w="140px"
          position="relative"
          flexShrink={0}
          borderRight="1px"
          borderColor="gray.700"
        >
          <Box position="relative" width="100%" height="100%">
            {!item.imageURL ? (
              <PlaceholderImage />
            ) : (
              <Image
                src={item.imageURL}
                alt={item.itemName}
                objectFit="cover"
                w="100%"
                h="100%"
                fallback={<PlaceholderImage />}
              />
            )}
          </Box>
        </Box>

        <VStack align="start" p={3} spacing={2} flex={1} h="100%">
          <VStack align="start" spacing={2} w="100%">
            <Text
              fontSize="lg"
              fontWeight="semibold"
              color="white"
              lineHeight="tight"
              noOfLines={1}
            >
              {item.itemName}
            </Text>

            {renderActionButton()}

            <HStack spacing={2}>
              <Badge
                colorScheme="purple"
                bg={item.store.color}
                color="white"
                px={2}
                py={1}
                borderRadius="full"
                fontSize="xs"
              >
                {item.store.shortName}
              </Badge>
              
              {item.isExclusive && (
                <Badge
                  bg="yellow.400"
                  color="black"
                  px={2}
                  py={1}
                  borderRadius="full"
                  fontSize="xs"
                  fontWeight="bold"
                  boxShadow="md"
                >
                  EXCLUSIVE
                </Badge>
              )}

              {/* Show total wants in shopping list */}
              {!isPantryList && normalizedNeedRequests.length > 0 && (
                <Badge
                  bg="purple.500"
                  color="white"
                  px={2}
                  py={1}
                  borderRadius="full"
                  fontSize="xs"
                  fontWeight="bold"
                  boxShadow="md"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  {normalizedNeedRequests.length} {normalizedNeedRequests.length === 1 ? 'Want' : 'Wants'}
                </Badge>
              )}

              {/* Show other users' needs in pantry view */}
              {isPantryList && otherUsersNeedCount > 0 && (
                <Badge
                  bg="blue.500"
                  color="white"
                  px={2}
                  py={1}
                  borderRadius="full"
                  fontSize="xs"
                  fontWeight="bold"
                  boxShadow="md"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  {otherUsersNeedCount} {otherUsersNeedCount === 1 ? 'Person Needs' : 'People Need'}
                </Badge>
              )}
            </HStack>
          </VStack>
        </VStack>

        {/* Favorite Star - Only show in Pantry List */}
        {isPantryList && (
          <Box
            position="absolute"
            bottom="32px"
            right="16px"
            onClick={handleFavoriteClick}
            cursor="pointer"
            zIndex={2}
          >
            <Icon
              as={StarIcon}
              w={5}
              h={5}
              color={isFavorite ? "yellow.400" : "transparent"}
              stroke={isFavorite ? "yellow.400" : "red.400"}
              strokeWidth="2px"
              transition="all 0.2s"
              _hover={{
                transform: 'scale(1.1)',
                color: isFavorite ? "yellow.300" : "transparent",
                stroke: isFavorite ? "yellow.300" : "red.300",
              }}
              opacity={isUpdatingFavorite ? 0.5 : 1}
            />
          </Box>
        )}
      </HStack>
    </Box>
  );
};

export default ItemCard;